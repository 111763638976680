



































































































import Vue from "vue";
import axios from "axios";
import { UserCreated } from "@/types/user";

const states = {
  idle: "idle",
  loading: "loading",
  loaded: "loaded",
  failed: "failed",
};

export default Vue.extend({
  name: "RegisterUserComponent",
  data: () => ({
    newUserCredentials: {
      email: "",
      password: "",
      repeatPassword: "",
    },
    valid: false,
    state: "idle",
    error: undefined,
    detail: [],
    states,
    token: "",
    headers: "Content-Type=application/x-www-form-urlencoded",
  }),
  methods: {
    CreateUser() {
      this.state = "loading";
      this.error = undefined;
      axios
        .post(`${this.$store.state.APIurl}/user/`, {
          email: `${this.newUserCredentials.email}`,
          password: `${this.newUserCredentials.password}`,
          repeated_password: `${this.newUserCredentials.repeatPassword}`,
        })
        .then((response) => {
          const response_data: UserCreated =
            response.data as unknown as UserCreated;
          this.state = "loaded";
        })
        .catch((error) => {
          if (error.response.status >= 400) {
            this.detail = error.response;
          }
          this.state = "failed";
          this.error = error;
        });
    },
  },
});
