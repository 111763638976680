var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',{staticStyle:{"justify-content":"center"}},[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"10","lg":"10"}},[_c('h1',[_vm._v("Registreer een account")]),_c('p',[_vm._v(" Op deze pagina kunnen accounts worden aangemaakt voor het gebruik van de applicatie. ")]),_c('v-divider',{staticClass:"mb-4"}),_c('h2',[_vm._v("Registreer")]),_c('v-form',{ref:"form",on:{"submit":function($event){$event.preventDefault();}},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12","lg":"12"}},[_c('v-text-field',{attrs:{"label":"E-mail adres","outlined":"","id":"newUserEmail"},model:{value:(_vm.newUserCredentials.email),callback:function ($$v) {_vm.$set(_vm.newUserCredentials, "email", $$v)},expression:"newUserCredentials.email"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12","lg":"12"}},[_c('v-text-field',{attrs:{"label":"Wachtwoord","outlined":"","id":"password","required":"","type":"password","autocomplete":"new-password","rules":[
                function (v) { return !!v || 'Wachtwoord moet zijn ingevuld.'; },
                function (v) { return (v && v.length >= 8) ||
                  'Wachtwoord moet minstens 8 karakters lang zijn.'; } ]},model:{value:(_vm.newUserCredentials.password),callback:function ($$v) {_vm.$set(_vm.newUserCredentials, "password", $$v)},expression:"newUserCredentials.password"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12","lg":"12"}},[_c('v-text-field',{attrs:{"label":"Herhaal wachtwoord","outlined":"","id":"repeatPassword","required":"","type":"password","autocomplete":"new-password","rules":[
                function (v) { return !!v || 'Wachtwoordherhaling moet zijn ingevuld.'; },
                this.newUserCredentials.password ===
                  this.newUserCredentials.repeatPassword ||
                  'Wachtwoord en wachtwoordherhaling moeten overeenkomen.' ]},model:{value:(_vm.newUserCredentials.repeatPassword),callback:function ($$v) {_vm.$set(_vm.newUserCredentials, "repeatPassword", $$v)},expression:"newUserCredentials.repeatPassword"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"6","lg":"12"}},[_c('v-btn',{attrs:{"disabled":!_vm.valid,"color":"primary","elevation":"1","block":"","large":"","id":"newUserSubmit"},on:{"click":_vm.CreateUser}},[_c('v-icon',{attrs:{"dark":"","medium":""}},[_vm._v(" mdi-plus ")]),_vm._v(" Registreer")],1)],1)],1)],1),(this.state === 'loaded')?[_c('v-alert',{staticClass:"mt-8",attrs:{"type":"success"}},[_c('p',[_c('strong',[_vm._v("Account succesvol aangemaakt. Uw QR-Code voor twee-factor-authenticatie is opgestuurd naar het opgegeven emailadres. Vervolgens kunt u inloggen met het opgegeven wachtwoord en teruggegeven 2FA code.")]),_c('br'),_vm._v(" Klik "),_c('router-link',{attrs:{"to":{ name: 'Login' }}},[_vm._v(" hier")]),_vm._v(" om naar de login-pagina te navigeren. ")],1)])]:_vm._e(),_c('br'),(this.error)?[_c('v-alert',{attrs:{"type":"error"}},[_vm._v(" Er is iets mis gegaan: "),_c('pre',[_vm._v(" "+_vm._s(this.detail.data.detail)+" ")])])]:_vm._e()],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }